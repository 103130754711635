<template>
  <div>
    <v-container>
      <v-card class="mx-auto col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <v-card-title class="d-flex justify-space-around">
          <h1 class="font-weight-medium text-center">
            Reporte de Pqrs Contraloría
          </h1>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col class="d-flex flex-wrap" cols="12" xs="12" sm="3" md="3">
              <span
                class="
                  text-subtitle-2
                  col-lg-12 col-md-12 col-sm-12 col-xs-12
                  pt-0
                  pb-0
                "
                :style="{ color: 'initial !important' }"
                >Rango de fecha (radicados)</span
              >

              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pt-0 pb-0">
                <v-menu
                  v-model="date_radicado.estado"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date_radicado.value"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      dense
                      hide-details
                      clearable
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    scrollable
                    v-model="date_radicado.value"
                    range
                    class="pa-0"
                    type="month"
                    locale="es"
                  >
                  </v-date-picker>
                </v-menu>
              </div>
            </v-col>

            <v-col class="d-flex flex-wrap" cols="12" xs="12" sm="2" md="2">
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pt-0 pb-0">
                &nbsp;
              </div>

              <div
                class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-right pa-0"
              >
                <v-btn
                  class="light-blue darken-1 white--text"
                  @click="get_report"
                >
                  consultar
                  <v-icon dark> &nbsp; mdi-magnify </v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>

    <v-container v-if="data_report.length > 0">
      <v-card class="mx-auto col-lg-12 col-md-12 col-sm-12 col-xs-12 pt-0 pb-0">
        <v-card-text class="pa-0">
          <v-data-table
            class="my-5"
            :headers="headers"
            :items="data_report"
            item-key="_id"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-btn color="red darken-2" dark @click="create_pdf">
                  Pdf &nbsp;
                  <v-icon dark> mdi-file-pdf </v-icon>
                </v-btn>

                <v-divider class="mx-4" inset vertical></v-divider>
                <v-btn
                  color="green darken-2"
                  dark
                  @click="create_file('excel')"
                >
                  Excel &nbsp;
                  <v-icon dark> mdi-file-excel </v-icon>
                </v-btn>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-btn color="green darken-2" dark @click="create_file('csv')">
                  Csv &nbsp;
                  <v-icon dark> mdi-file-delimited </v-icon>
                </v-btn>
              </v-toolbar>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import { addDaysWithoutHolidays } from "../../utils";

export default {
  data() {
    return {
      data_servicios: [{ value: 1, text: "Todos los servicios" }],
      form: {
        srv_id: "**",
      },
      date_radicado: {
        estado: false,
        value: null,
      },
      data_report: [],
      headers: [
        { text: "Numero radicado", value: "nro_rad_frpqr" },
        { text: "Fecha radicado", value: "f_rad_frpqr" },
        { text: "Tipo de peticion", value: "tpq_nmbre" },
        { text: "Observaciones", value: "observ_frpqr" },
      ],

      data_impresion: {
        header: [
          {
            name: "1. Nro Radicado",
            pointer: "nro_rad_frpqr",
            filterButton: true,
          },
          {
            name: "2. Fecha radicado",
            pointer: "f_rad_frpqr",
            filterButton: true,
            format: "date",
          },
          {
            name: "3. Medio de llegada",
            pointer: "m_entr_frpqr",
            filterButton: true,
          },
          {
            name: "4. Tipo de peticion",
            pointer: "tpq_nmbre",
            filterButton: true,
          },
          {
            name: "5. Entidad requerida",
            pointer: "ent_afec_frpqr",
            filterButton: true,
          },
          {
            name: "6. Fecha limite respuesta",
            pointer: "f_limit_resp_frpqr",
            filterButton: true,
            format: "date",
          },
          {
            name: "7. Entidad a resolver",
            pointer: "ent_resp_frpqr",
            filterButton: true,
          },
          {
            name: "8. Fecha traslado",
            pointer: "f_limit_tras_frpqr",
            filterButton: true,
            format: "date",
          },
          {
            name: "9. Entidad a intervenir",
            pointer: "ent_int_frpqr",
            filterButton: true,
          },
          {
            name: "10. Dependencia responsable",
            pointer: "dep_nmbre",
            filterButton: true,
          },
          {
            name: "11. Fecha de comunicaciòn",
            pointer: "f_comunic_frpqr",
            filterButton: true,
            format: "date",
          },
          {
            name: "12. Fecha de respuesta",
            pointer: "f_resp_frpqr",
            filterButton: true,
            format: "date",
          },
          {
            name: "13. Estado tramite",
            pointer: "estado_text",
            filterButton: true,
          },
          {
            name: "14. Observaciones",
            pointer: "observ_frpqr",
            filterButton: true,
          },
        ],
        content: [],
      },
      dnl: [],
    };
  },
  created() {
    this.getServicios();
  },
  methods: {
    getServicios() {
      let uri = "/servicios/" + String(this.$store.state.company);

      this.axios
        .get(uri)
        .then(async (response) => {
          let params = [response.data, "srv_cdgo", "srv_nombre"];
          this.data_servicios = await this.gb.orderData(params);

          this.data_servicios.push({
            value: "**",
            text: "Todos los servicios",
          });
        })
        .catch((error) => {
          this.$emit("msj", { text: error.message });
        });
    },

    get_report() {
      if (!this.date_radicado.value) {
        alert("Por favor seleccionar el rango de fechas.");
        return;
      }

      let d = this.date_radicado.value;

      if (d.length == 1) {
        d.push(d[0]);
      }

      var di = d[0] + "-" + "01";

      let year = parseInt(d[1].substr(0, 4));
      let month = parseInt(d[1].substr(5, 2));
      let lf = new Date(year, month, 0);
      let ld = lf.getDate();

      var df = d[1] + "-" + ld;

      let date = [di, df] || [];
      let srv_id = this.form.srv_id;

      let data = {
        date_ini: date[0],
        date_fin: date[1],
        cmp_id: String(this.$store.state.company),
        sspd: true,
      };

      if (srv_id != "**") data.srv_id = srv_id;

      var date_fin = new Date(df);

      let estados = [
        { value: 1, text: "Abierto" },
        { value: 2, text: "Cerrado" },
      ];

      this.axios
        .post("/formatopqrs/report/pqrs", data)
        .then((response) => {
          var list = response.data.map((e) => {
            e.f_rad_frpqr = this.formatDate(e.f_rad_frpqr);
            e.f_limit_resp_frpqr = this.formatDate(e.f_limit_resp_frpqr);
            e.f_limit_tras_frpqr = this.formatDate(e.f_limit_tras_frpqr);
            e.f_comunic_frpqr = this.formatDate(e.f_comunic_frpqr);
            e.f_resp_frpqr = this.formatDate(e.f_resp_frpqr);
            e.estado_text =
              estados.find((a) => a.value == e.est_tram_frpqr)?.text || "";

            this.fetchDianol(e.f_rad_frpqr, e.dcm_tres, () => {
              let endDate = addDaysWithoutHolidays({
                date_start: e.f_rad_frpqr,
                days: e.dcm_tres,
                dnl: JSON.parse(JSON.stringify(this.dnl)),
              });

              e.f_limit_resp_frpqr = endDate
            });

            return e;
          });

          this.data_report = list;
        })
        .catch((err) => {
          console.log(err);
          this.$emit("msj", { text: error.message });
        });
    },
    async create_file(type = "excel") {
      this.data_impresion.content = _.cloneDeep(this.data_report);

      let datos = {
        tipo: type,
        datos: this.data_impresion,
      };

      let new_impresion = new this.gb.Impresion(datos);

      new_impresion.init(
        (res) => {
          console.log(res);
        },
        (err) => {
          console.log(err);
        }
      );
    },

    async create_pdf() {
      this.data_impresion.content = _.cloneDeep(this.data_report);
      let datos = {
        tipo: "pdf",
        datos: this.data_impresion,
        formato: "FormatoFrPqrs",
      };

      let new_impresion = new this.gb.Impresion(datos);

      new_impresion.init(
        (res) => {
          console.log(res);
        },
        (err) => {
          console.log(err);
        }
      );
    },

    formatDate(date) {
      if (!date) return null;
      else {
        let fecha = date.substring(0, 10);

        if (fecha == "1970-01-01") return null;
        else return fecha;
      }
    },
    async fetchDianol(startDate, noOfDaysToAdd, callback) {
      var data = {
        cmp_id: this.$store.state.company,
        inicio: startDate,
        fin: await this.addDays(startDate, noOfDaysToAdd),
      };

      let uri = "/dianols/rango";
      this.axios
        .post(uri, data)
        .then((response) => {
          this.dnl = response.data;
          return callback(false);
        })
        .catch((err) => {
          return callback(true);
        });
    },

    async addDays(startDate, noOfDaysToAdd = 0) {
      startDate = new Date(startDate.replace(/-/g, "/"));

      let endDate = "";
      let count = 0;
      noOfDaysToAdd = noOfDaysToAdd * 2;

      while (count < noOfDaysToAdd) {
        endDate = new Date(startDate.setDate(startDate.getDate() + 1));

        if (endDate.getDay() != 0 && endDate.getDay() != 6) {
          count++;
        }
      }

      return endDate.toLocaleDateString().split("/").reverse().join("-");
    },
  },
};
</script>

<style>
.v-data-table .v-toolbar.v-toolbar--flat {
  display: flex;
  justify-content: flex-end;
}
</style>